<template>
  <div class="bottom-menu">
    <div
      :class="['bottom-menu__item', { active: menuItem.active }]"
      v-for="(menuItem, idx) in menuList"
      :key="idx"
    >
      <font-awesome-icon :icon="menuItem.icon" />
      <router-link :to="menuItem.link ? menuItem.link : ''" class="label">{{
        menuItem.label
      }}</router-link>

      <div class="sub-list" v-if="menuItem.list && menuItem.list.length">
        <div
          :class="[
            'sub-list__item',
            { active: subItem.relation === $route.meta.relation },
          ]"
          v-for="(subItem, subItemIdx) in menuItem.list"
          :key="subItemIdx"
        >
          <router-link :to="subItem.link ? subItem.link : ''" class="label">
            {{ subItem.label }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="bottom-menu__item">
      <font-awesome-icon
        icon="custom-menu"
        class="open-menu"
        @click="action(true)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomMenu",
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.bottom-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 74px;
  width: 100%;
  z-index: 1001;
  background-color: $white;
  box-shadow: 0px -3px 15px -6px #0000002e;
  padding: 15px;
  overflow-y: visible;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: $scrollbar_height;
  }

  &::-webkit-scrollbar-track {
    background: $light_gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $focus_color;
  }

  // @media (min-width: 768px) {
  //     left: 50px;
  //     width: calc( 100% - 50px );
  // }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    color: $black;
    text-decoration: none;
    cursor: pointer;
    padding: 0 15px;

    &:hover,
    &.active {
      color: $purple;

      .sub-list {
        opacity: 1;
      }
    }

    .label {
      color: inherit;
      font-size: 11px;
      text-decoration: none;
    }

    .sub-list {
      position: absolute;
      bottom: calc(100% + 15px);
      padding: 15px;
      box-shadow: 0px -3px 15px -6px #0000002e;
      background-color: $white;
      opacity: 0;

      &__item {
        color: $dark;
        white-space: nowrap;

        &.active {
          color: $purple;
        }
      }
    }
  }

  .open-menu {
    font-size: 2rem;
    cursor: pointer;
  }
}
</style>
