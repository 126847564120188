<template>
  <div class="profile-card">
    <div class="user-img">
      <img src="" alt="" v-if="profileImagePath !== ''" />
      <font-awesome-icon
        v-if="profileImagePath == ''"
        icon="user-circle"
        size="3x"
        class="user-avatar"
      />
    </div>
    <div class="user-info">
      <div class="user-name">{{ username }}</div>
      <div class="profile-link">
        <router-link :to="link">{{ linkLabel }}</router-link>
      </div>
    </div>
    <div class="user-logout" @click="logoutMt">
      <font-awesome-icon icon="sign-out-alt" />
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faUserCircle, faSignOutAlt);

import { mapActions } from "vuex";

export default {
  name: "ProfileAndLogout",
  props: {
    profileImagePath: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: () => ({}),
      // required: true,
    },
    linkLabel: {
      type: String,
      default: "",
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    username() {
      return this.$store.getters["user/get"].username;
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      logout: "logout",
    }),
    logoutMt() {
      this.logout().then((res) => {
        if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
        this.$router.push("/");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.profile-card {
  display: flex;

  & .user-img {
    box-sizing: border-box;
    flex: 0 0 50px;
    max-width: 50%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: block;

    .user-avatar {
      color: lightgray;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      border-style: none;
    }
  }

  & .user-info {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding-left: 7.5px;

    .user-name {
      font-size: 16px;
      font-weight: 600;
      font-family: "Oswald", sans-serif;
      color: $black;
      line-height: 1.5;
      text-align: left;
    }

    a {
      text-align: right;
      color: #7f7f7f;
      font-size: 10px;
      text-decoration: none;
      background-color: transparent;
    }

    @media screen and (min-width: 1200px) {
      padding-left: 15px;
    }

    @media screen and (min-width: 1600px) {
      padding-left: 25px;
    }
  }

  .user-logout {
    margin-left: 24px;
    align-items: center;
    display: inline-flex;
    cursor: pointer;

    svg {
      font-size: 22px;
      color: $focus_color;
      text-decoration: none;
    }
  }
}

.module-editor {
  .profile-card {
    & .user-info {
      @media screen and (min-width: 1200px) {
        padding-left: 15px;
      }

      @media screen and (min-width: 1600px) {
        padding-left: 25px;
      }
    }
  }
}
</style>
